export const ADD_INSURANCE_COMPANY = "ADD_INSURANCE_COMPANY";

export const ADD_ALLERGY_TYPE = "ADD_ALLERGY_TYPE";

export const ADD_APP_ACCESS_TOKEN = "ADD_APP_ACCESS_TOKEN";

export const ADD_BLOOD_GROUP = "ADD_BLOOD_GROUP";

export const ADD_CARE_MANAGER = "ADD_CARE_MANAGER";

export const ADD_CITY = "ADD_CITY";

export const ADD_COMPANY = "ADD_COMPANY";

export const ADD_CONDITION_TYPE = "ADD_CONDITION_TYPE";

export const ADD_COUNTRY = "ADD_COUNTRY";

export const ADD_DIETICIAN = "ADD_DIETICIAN";

export const ADD_DIET_PLAN = "ADD_DIET_PLAN";

export const ADD_DOCTOR = "ADD_DOCTOR";

export const ADD_HOSPITAL = "ADD_HOSPITAL";

export const ADD_REFER_BY_TYPE = "ADD_REFER_BY_TYPE";

export const ADD_STATE = "ADD_STATE";

export const ADD_SURGERY_TYPE = "ADD_SURGERY_TYPE"; 

export const ADD_USER_TYPE = "ADD_USER_TYPE";

export const ADD_VITAL_TYPE = "ADD_VITAL_TYPE";

export const ADD_LOGIN_TYPE = "ADD_LOGIN_TYPE";